$enable-smooth-scroll: false;

// Text
$font-main: "Poppins", sans-serif !default;
$font-heading: "Poppins", sans-serif !default;
$font-family-base: $font-main !default;
$font-size-base: 0.875rem !default;
$font-size-main: 15px !default;
$font-size-secondary: 13px !default;
$line-height-base: 1.4 !default;
$line-height-lg: 1.4 !default;
$line-height-sm: 1.4 !default;

// Breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// Container
$max-width-container: 1248px;
$container-max-widths: (
  xxl: $max-width-container,
);

// Grid
$grid-gap-sm: 1rem;
$grid-gap: 1.5rem;
$grid-gutter-width: $grid-gap-sm;
$container-padding-x: $grid-gap-sm;

// Common
$transition-style: 0.3s ease-in-out;
$box-shadow: 8px 16px 24px rgba(0, 0, 0, 0.1) !default;
$filter-shadow: drop-shadow(0 6px 12px hsla(211deg, 47%, 19%, 0.15)) !default;
$border-radius: 8px !default;
$border-radius-lg: 8px !default;
$border-radius-sm: 4px !default;
$border-radius-btn: 40px !default;
$input-btn-font-family: $font-heading !default;
$border-radius-input: 8px !default;

// search form
$search-form-height: 44px !default;
$search-form-height-lg: 58px !default;
$search-form-btn-size: $search-form-height !default;
$search-form-btn-size-lg: $search-form-height-lg !default;
$search-form-btn-radius: $border-radius-btn !default;
$search-form-input-radius: $border-radius-btn !default;
$table-cell-padding: 0;

// Z-Indexes
$z-index-pw: 9999;
$z-index-header: 100;

// Header
$header-height: 80px !default;
$header-height-mob: 64px !default;
$header-pict-height: 256px !default;
$header-pict-height-mob: 150px !default;
$header-icon-size: 24px !default;
$header-icon-size-sm: 32px !default;

// Tables
$table-cell-padding-y: 8px;
$table-cell-padding-x: 12px;
$table-cell-padding-y-sm: 4px;
$table-cell-padding-x-sm: 8px;

// Dropdowns
$dropdown-min-width: 80px !default;

// Tooltip
$tooltip-opacity: 1;
$tooltip-padding-y: 8px;
$tooltip-padding-x: 8px;
$tooltip-max-width: 275px;
$tooltip-font-size: 12px;

// Other
$informa-bar-bottom-border: 0;
$tnl-title-size: 22px !default;
$tnl-title-size-md: 33px !default;
$scrollbar-size: 15px;

/* stylelint-disable scss/no-global-function-names */
/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-empty-line-before */
$cl-green-dark: #004040;
$cl-green-dark-90: #1a5353;
$cl-green-dark-50: #809f9f;
$cl-green-dark-20: #ccd9d9;
$cl-green-dark-10: #e6ecec;
$cl-green-dark-3: #f7f9f9;

$cl-green-darker: #38a87f;
$cl-green: #41c092;
$cl-green-50: #a0dfc8;
$cl-green-40: #b3e6d3;
$cl-green-20: #d9f2e9;
$cl-green-20n: #dcefe8;
$cl-green-10: #ecf8f4;

$cl-black: #000;
$cl-black-80: #333;
$cl-black-50: #808080;
$cl-black-20: #ccc;

$cl-blue: #0e81d0;
$cl-success: #52b788;
// $cl-error: #A30000;
$cl-error: #d92d20;

$cl-gray-dark: #515b5d;
$cl-gray: $cl-black-50;
$cl-gray-middle: $cl-green-dark-20;
$cl-gray-light: #f0f0f0;
$cl-white: #fff;

$cl-facebook: #3b5998;
$cl-twitter: #1da1f2;
$cl-youtube: #ff0000;
$cl-vimeo: #1ab7ea;
$cl-linkedin: #0a66c2;
$cl-blogg: #ff6600;
$cl-pinterest: #e60023;

$cl-admin-night: $cl-green-dark !default; // Admin sidebar bg
$cl-admin-night-icons: $cl-green !default;
$cl-admin-night-text: $cl-green-40 !default;

$cl-main-dark-2: $cl-green-dark-90 !default;
$cl-main-dark: $cl-green-dark !default;
$cl-main: $cl-green !default;
$cl-main-light: $cl-green-50 !default;
$cl-main-lightest: $cl-green-40 !default;
$cl-main-super-light: $cl-green-dark-10 !default;
$cl-secondary: $cl-green !default;
$cl-accent: $cl-green !default;

$primary: $cl-green-dark !default;
$secondary: $cl-green !default;
$accent: $cl-accent !default;
$info: $cl-blue !default;
$success: $cl-success !default;
$warning: #fb8b24 !default;
$danger: $cl-error !default;

$cl-text: $cl-black-80 !default;
$text-muted: $cl-gray-dark !default;

$link-color: $secondary !default;
$link-hover-color: $primary !default;

$body-bg: $cl-white !default;
$body-bg-sec: $cl-green-dark-3 !default;
$body-color: $cl-text !default;

$bg-l-gray: $cl-main-super-light !default;
$bg-light-2: #eef8f8 !default;

$input-disabled-bg: $cl-gray-light !default;

$cl-line-main: $cl-green-dark-20 !default;
$border-color: $cl-line-main !default;

$cl-bg-darker: $cl-green-dark-3 !default;

$tooltip-bg: $primary !default;

$row-even-bg: rgba($cl-main-super-light, 0.5) !default;

$button-text: $cl-main-dark !default;

$table-color: $body-color !default;
$table-bg: transparent !default;

$table-striped-color: $text-muted !default;
$table-striped-bg: $cl-main-super-light !default;
$table-border-color: $border-color !default;

// Admin
$admin-bg: $body-bg-sec !default;
$admin-sidebar-bg: $cl-admin-night !default;
$admin-sidebar-link: #ccdada !default;
$admin-sidebar-link-hover: $cl-green-dark-90 !default;
$admin-sidebar-icon: $cl-main-light !default;
$admin-sidebar-line: $cl-green-dark-90 !default;

// Site
$search-form-border-color: $border-color !default;
$search-form-btn-bg: none !default;
$search-form-btn-bg-hover: none !default;
$search-form-btn-color: $secondary !default;
$search-form-btn-color-hover: $primary !default;
$search-form-input-bg: #fff !default;

$header-bg-main: $body-bg-sec !default;
$header-icon-primary-color: $secondary !default;
$header-icon-secondary-color: $secondary !default;
$header-button-color: $secondary !default;
$header-button-text: $cl-main-dark !default;
$header-button-color-hover: darken($header-button-color, 7.5%) !default;
$header-button-text-hover: #fff !default;

$mha-link-color-sm: $secondary !default;
$mha-link-color-lg: $secondary !default;
$mha-bage-color: $cl-main-dark !default;

$main-menu-bg-sm: #fff !default;
$main-menu-color-sm: $cl-main-dark !default;
$main-menu-bg-lg: $cl-green-20n !default;
$main-menu-color-lg: $cl-main-dark !default;

$breadcrumb-bg: $cl-green-dark-3 !default;
$breadcrumb-text: $cl-green-dark-50 !default;
$breadcrumb-link: $cl-green-dark !default;
$breadcrumb-link-hover: $secondary !default;
$breadcrumb-sep: #acb6c0 !default;

$footer-text: $cl-gray-dark !default;
$informa-footer-top-bg: $cl-black-80 !default;
$informa-footer-top-text: #fff !default;
$informa-footer-bottom-bg: $cl-black-80 !default;
$informa-footer-bottom-text: #fff !default;

$tag-l-green-color: $cl-green-dark-10 !default;
$tag-type-color: $cl-green-40 !default;
$tag-type-text: $cl-main-dark !default;
$tag-primary-color: $cl-accent !default;
$tag-primary-text: $primary !default;
$tag-blue-color: $cl-green-20n !default;
$tag-blue-text: $cl-main-dark !default;

$type-color: $cl-green-50 !default;
$type-text: $cl-main-dark !default;

$b-progress-bg: $cl-green-dark-10 !default;
$b-progress-bar-bg1: $cl-green-40 !default;
$b-progress-bar-bg2: rgba($secondary, 0.3) !default;
$b-progress-bar-fill: $secondary !default;

$progress-bar-bg: $cl-green-dark-20 !default;
$progress-bar-fill: $cl-green-dark-50 !default;
$progress-bar-bg-primary: $cl-green-40 !default;
$progress-bar-fill-primary: $secondary !default;
$progress-bar-bg-secondary: $cl-green-dark-50 !default;
$progress-bar-fill-secondary: $primary !default;

$cphi-verified-color: $secondary !default;

$subscribe-tsr-icon-color: #fff !default;

$fav-check-color: $cl-green-dark-50 !default;
$fav-check-color-hover: $cl-green-dark-90 !default;

$sm-tag-list-dot: $cl-green !default;

$s-sect-title-color: $cl-green-dark-50 !default;
$s-sect-title-dark-color: $cl-green-dark-90 !default;

$c-card-title-color: $cl-green-dark-50 !default;
$e-exh-bg: $cl-main-dark !default;

$pd-stat-color: $cl-main-dark !default;
$pd-stat-text: #fff !default;

$category-list-m-link-color: $cl-green !default;

$contacts-p-dark-sect-color: $cl-main-dark !default;
$contacts-p-dark-sect-pict-bg: $cl-green-dark-90 !default;
$contacts-p-dark-sect-link: $secondary !default;
$contacts-p-dark-sect-link-hover: $cl-green-50 !default;

$subscription-title-accent-text: #fff !default;

$step-arrow-color: $secondary !default;

$plan-platinum-title-bg: $cl-main-dark !default;
$plan-platinum-body-bg: #e6f8f4 !default;
$plan-platinum-border: #d1e0df !default;

$gr-timeline-stage-border: $cl-green-dark-50 !default;
$gr-timeline-stage-fill: $cl-green-dark-20 !default;
$gr-timeline-item-title-border: $cl-green-50 !default;

$ftr-yes-bg: $cl-green-50 !default;
$ftr-yes-color: $cl-main-dark !default;

$pagination-norm-bg: $cl-green-50 !default;
$pagination-norm-color: $cl-main-dark !default;
$pagination-hover-bg: $cl-accent !default;
$pagination-hover-color: $cl-main-dark !default;
$pagination-active-bg: $cl-main-dark !default;
$pagination-active-color: #fff !default;

$flt-btn-color: $cl-main-dark !default;
$flt-btn-bg: $cl-green-20 !default;
$flt-btn-color-hover: $cl-main-dark !default;
$flt-btn-bg-hover: $cl-accent !default;
$flt-btn-color-active: #fff !default;
$flt-btn-bg-active: $cl-main-dark !default;

$diagram-secondary: $secondary !default;

$publish-note-bg: $cl-green-20 !default;

$reach-sect-text: $cl-accent !default;
$reach-sect-bg: $cl-main-dark !default;

$input-bg: #fff;

/* @import "theme";
@import "colors";
@import "variables";
@import "admin/admin-variables";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "mixins"; */

@import "informa-corporate-critical";

.informa-bar {
  $block: &;

  &__content {
    a {
      color: #e1e1e1;
      text-decoration: none;

      &:hover {
        color: #e1e1e1;
        text-decoration: underline;
      }
    }
  }

  &__cols {
    padding: 16px 0 8px;
    max-width: 1080px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;

      &::after {
        content: '';
        display: block;
        width: 0;
        margin: 0 20px;
        border-left: 1px solid currentColor;
        order: 1;
        align-self: stretch;
      }
    }
  }

  &__col {
    @include media-breakpoint-up(md) {
      flex: 1;

      &--info {
        order: 2;
      }
    }
  }

  &__toggle {
    background: url(https://www.cphi-online.com/46/homepage/00/11/28/img/informa_white_double2.png) no-repeat center top;
    background-size: 100% auto;
    cursor: pointer;
    transition: none;

    #{$block}--opened & {
      background-position: center bottom;
    }
  }
}

.ib-links-list {
  margin: 0 0 1rem;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    display: block;

    &::after {
      content: '|';
      display: inline-block;
      margin: 0 8px;
    }

    &:last-child::after {
      content: normal;
    }

    &--home {
      font-weight: 600;
      margin-right: 20px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-right: 0;
      }

      &::after {
        content: normal;
      }
    }
  }
}

.informa-footer {
  margin-top: 30px;
  font-size: $font-size-secondary;
  line-height: round($font-size-secondary * 1.4);

  &__main {
    color: $informa-footer-top-text;
    background: $informa-footer-top-bg;
    padding: 20px 0;

    a {
      color: $informa-footer-top-text;
      text-decoration: none;

      &:hover {
        color: $informa-footer-top-text;
        text-decoration: underline;
      }
    }
  }

  &__links {
    color: $informa-footer-bottom-text;
    background: $informa-footer-bottom-bg;
    padding: 20px 0;

    a {
      color: $informa-footer-bottom-text;
      text-decoration: none;

      &:hover {
        color: $informa-footer-bottom-text;
        text-decoration: underline;
      }
    }
  }
}

.corp-info {
  text-align: center;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 260px;
    margin: 0 auto 16px;

    @include media-breakpoint-up(lg) {
      margin-right: 40px;
      margin-bottom: 0;
      margin-left: 0;
    }

    img {
      max-width: 100%;
    }
  }

  &__copy {
    @include media-breakpoint-up(lg) {
      margin-top: 8px;
      text-align: right;
      min-width: 0;
      flex: 1;
    }
  }
}

.corp-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }

  &__item {
    margin: 0 0 5px;

    &::after {
      content: '|';
      margin: 0 8px;
    }

    &:last-child::after {
      content: normal;
    }
  }

  &__link {
    &:hover {
      text-decoration: underline;
    }
  }
}

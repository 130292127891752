.informa-bar {
  color: #e1e1e1;
  background: #525a5c;
  border-bottom: $informa-bar-bottom-border;
  font-family: $font-heading;
  font-size: 13px;
  line-height: 21px;
  padding-top: 1px;
  position: relative;
  z-index: $z-index-header;

  &__content {
    overflow: hidden;
  }

  &__buttons {
    height: 16px;
    margin: 7px auto 8px;
    text-align: center;
  }

  &__toggle {
    // background: url(https://www.cphi-online.com/46/homepage/00/11/28/img/informa_white_double2.png) no-repeat center top;
    background-size: 100% auto;
    border: 0;
    outline: 0;
    margin-right: -10px;
    box-shadow: none;
    display: inline-block;
    width: 92px;
    height: 16px;
    overflow: hidden;
  }
}
